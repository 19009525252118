<template>
  <div>
    <page-toolbar :title="pageToolbarTitle">
      <template slot="action">
        <slot name="toolbar-action"></slot>
      </template>
    </page-toolbar>
    <v-container>
      <page-title :title="pageTitle"></page-title>

      <v-sheet class="d-flex flex-row flex-wrap align-center mb-3 py-1 rounded" elevation="1">
        <app-button v-if="!hideBackButton" @click="$router.go(-1)" title="Back" mdi-icon="mdi-arrow-left" class="ml-2"></app-button>
        <v-divider v-if="!hideBackButton" vertical class="mx-2"></v-divider>

        <div v-if="recordNotFound === false" class="d-flex">
          <slot name="toolbar"></slot>
        </div>
      </v-sheet>

      <div v-if="recordNotFound === false">
        <slot></slot>
      </div>

      <v-card v-else >
        <v-card-text class="text-center py-12">
          <div class="mb-4">
            <v-icon size="80" color="error">mdi-alert-circle-outline</v-icon>
          </div>
          <h2 class="mb-2">Record Not Found !</h2>
          <p>Oops! We couldn't find the record you're looking for.</p>
          <v-btn @click="goTo('/')" color="primary" outlined>Back to Home</v-btn>
        </v-card-text>
      </v-card>

      <v-sheet class="d-flex flex-row flex-wrap align-center mb-3 py-1 rounded" elevation="1" v-if="showActionBottom">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="light" class="mr-1" v-bind="attrs" v-on="on" @click="$router.go(-1)" icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </template>
          <span>Back</span>
        </v-tooltip>
        <v-divider vertical class="mr-2"></v-divider>
        <slot name="toolbar"></slot>
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    pageToolbarTitle: {
      type: String,
      default: "",
    },
    pageTitle: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showActionBottom: {
      type: Boolean,
      default: false,
    },
    hideBackButton: {
      type: Boolean,
      default: false,
    },
    recordNotFound: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
