import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Root",
    component: () => import("@/views/dashboard/index.vue"),
    redirect: () => {
      let _route = "dashboard";
      return _route;
    },
    meta: {
      requiredLogin: true,
      layout: "default",
      routeMap: "Dashboard",
      transition: "fade",
      title: "Dashboard",
      breadcrumbs: [],
    },
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/views/dashboard/index.vue"),
    meta: {
      requiredLogin: true,
      layout: "default",
      routeMap: "Dashboard",
      title: "Dashboard",
      breadcrumbs: [
        {
          text: "Dashboard",
          disabled: true,
          href: "/dashboard",
        },
      ],
    },
  },
  // Sales
  {
    path: "/sales",
    name: "Sales",
    // redirect: '',
    component: { render: (h) => h("router-view") },
    children: [
      {
        path: "customer",
        name: "Sales.Customer",
        component: () => import("@/views/sales/customer/index.vue"),
        meta: {
          requiredLogin: true,
          layout: "default",
          routeMap: "Sales.Customer",
          title: "Customer | Sales",
        },
      },
      {
        path: "contact",
        name: "Sales.Contact",
        component: { render: (h) => h("router-view") },
        meta: {
          requiredLogin: true,
          layout: "default",
          routeMap: "Sales.Contact",
          title: "Contact | Sales",
        },
        children: [
          {
            path: "/",
            name: "Sales.Contact",
            component: () => import("@/views/sales/contact/index"),
            meta: {
              requiredLogin: true,
              layout: "default",
              routeMap: "Sales.Contact",
              title: "Contact | Sales",
              breadcrumbs: [
                {
                  text: "Sales",
                  disabled: true,
                  href: null,
                },
                {
                  text: "Contact",
                  disabled: true,
                  href: "/sales/contact",
                },
              ],
            },
          },
          {
            path: "add",
            name: "Sales.Contact.Add",
            component: () => import("@/views/sales/contact/ContactAdd.vue"),
            meta: {
              requiredLogin: true,
              layout: "default",
              routeMap: "Sales.Contact",
              title: "Add | Contact | Sales",
              breadcrumbs: [
                {
                  text: "Sales",
                  disabled: true,
                  href: null,
                },
                {
                  text: "Contact",
                  disabled: false,
                  to: {
                    name: "Sales.Contact",
                  },
                },
                {
                  text: "Add",
                  disabled: true,
                  href: null,
                },
              ],
            },
          },
          {
            path: "edit",
            name: "Sales.Contact.Edit",
            component: () => import("@/views/sales/contact/ContactEdit.vue"),
            meta: {
              title: "Edit | Contact | Sales",
              requiredLogin: true,
              layout: "default",
              routeMap: "Sales.Contact",
              breadcrumbs: [
                {
                  text: "Sales",
                  disabled: true,
                  href: null,
                },
                {
                  text: "Contact",
                  disabled: false,
                  to: {
                    name: "Sales.Contact",
                  },
                },
                {
                  text: "Edit",
                  disabled: true,
                  href: null,
                },
              ],
            },
          },
        ],
      },
      {
        path: "campaign",
        name: "Sales.Campaign",
        component: { render: (h) => h("router-view") },
        meta: {
          title: "Campaign | Sales",
          requiredLogin: true,
          layout: "default",
          routeMap: "Sales.Campaign",
        },
        children: [
          {
            path: "/",
            name: "Sales.Campaign",
            component: () => import("@/views/sales/campaign/index"),
            meta: {
              title: "Campaign | Sales",
              requiredLogin: true,
              layout: "default",
              routeMap: "Sales.Campaign",
            },
          },
          {
            path: "detail",
            name: "Sales.Campaign.Detail",
            component: () => import("@/views/sales/campaign/CampaignDetail.vue"),
            meta: {
              title: "Detail | Campaign | Sales",
              requiredLogin: true,
              layout: "default",
              routeMap: "Sales.Campaign",
            },
          },
        ],
      },
      {
        path: "/sales/documentuploads",
        name: "Sales.DocumentUploads",
        component: () => import("@/views/sales/documentuploads/DocumentUploads.vue"),
        meta: {
          title: "Document Uploads | Sales",
          requiredLogin: true,
          routeMap: "Sales.DocumentUploads",
        },
      },
      {
        path: "/sales/ticket",
        name: "Sales.Ticket",
        component: { render: (h) => h("router-view") },
        meta: {
          title: "Ticket | Sales",
          requiredLogin: true,
          layout: "default",
          routeMap: "Sales.Ticket",
        },
        children: [
          {
            path: "/",
            name: "Sales.Ticket",
            component: () => import("@/views/sales/ticket/index.vue"),
            meta: {
              title: "Ticket | Sales",
              requiredLogin: true,
              layout: "default",
              routeMap: "Sales.Ticket",
            },
          },
          {
            path: "detail",
            name: "Sales.Ticket.Detail",
            component: () => import("@/views/sales/ticket/Detail.vue"),
            meta: {
              title: "Detail | Ticket | Sales",
              requiredLogin: true,
              layout: "default",
              routeMap: "Sales.Ticket.Detail",
            },
          },
        ],
      },
      {
        path: "salesprice",
        name: "Sales.SalesPrice",
        component: { render: (h) => h("router-view") },
        meta: {
          title: "Sales Price | Sales",
          requiredLogin: true,
          routeMap: "Sales.SalesPrice",
        },
        children: [
          {
            path: "/",
            name: "Sales.SalesPrice",
            component: () => import("@/views/sales/salesprice/index.vue"),
            meta: {
              requiredLogin: true,
              layout: "default",
              routeMap: "Sales.SalesPrice",
              title: "Sales - Sales Price",
            },
          },
        ],
      },
    ],
  },
  // Warehouse
  {
    path: "/warehouse",
    name: "Warehouse",
    // redirect: '',
    component: { render: (h) => h("router-view") },
    meta: {
      requiredLogin: true,
      routeMap: "Warehouse",
      title: "Warehouse",
    },
    children: [
      {
        path: "rmcriteria",
        name: "Warehouse.RMCriteria",
        component: () => import("@/views/warehouse/rmcriteria/index.vue"),
        meta: {
          title: "RM Criteria | Warehouse",
          requiredLogin: true,
          layout: "default",
          routeMap: "Warehouse.RMCriteria",
        },
      },
      {
        path: "rmp",
        name: "Warehouse.ReturnMaterial",
        component: { render: (h) => h("router-view") },
        meta: {
          title: "Return Material | Warehouse",
          requiredLogin: true,
          routeMap: "Warehouse.ReturnMaterial",
        },
        children: [
          {
            path: "/",
            name: "Warehouse.ReturnMaterial",
            component: () => import("@/views/warehouse/rmp/index.vue"),
            meta: {
              title: "Return Material | Warehouse",
              requiredLogin: true,
              layout: "default",
              routeMap: "Warehouse.ReturnMaterial",
            },
          },
          {
            path: "edit",
            name: "Warehouse.ReturnMaterial.Edit",
            component: () => import("@/views/warehouse/rmp/RmpEdit.vue"),
            meta: {
              title: "Edit | Return Material | Warehouse",
              requiredLogin: true,
              layout: "default",
              routeMap: "Warehouse.ReturnMaterial",
            },
          },
          {
            path: "view",
            name: "Warehouse.ReturnMaterial.View",
            component: () => import("@/views/warehouse/rmp/RmpView.vue"),
            meta: {
              title: "View | Return Material | Warehouse",
              requiredLogin: true,
              layout: "default",
              routeMap: "Warehouse.ReturnMaterial",
            },
          },
        ],
      },

      {
        path: "item",
        name: "Warehouse.Item",
        component: { render: (h) => h("router-view") },
        meta: {
          requiredLogin: true,
          routeMap: "Warehouse.Item",
          title: "Item | Warehouse",
        },
        children: [
          {
            path: "/",
            name: "Warehouse.Item",
            component: () => import("@/views/warehouse/item/index.vue"),
            meta: {
              title: "Item | Warehouse",
              requiredLogin: true,
              layout: "default",
              routeMap: "Warehouse.Item",
            },
          },
        ],
      },

      {
        path: "bincontent",
        name: "Warehouse.BinContent",
        component: { render: (h) => h("router-view") },
        meta: {
          title: "Bin Content | Warehouse",
          requiredLogin: true,
          routeMap: "Warehouse.BinContent",
        },
        children: [
          {
            path: "/",
            name: "Warehouse.BinContent",
            component: () => import("@/views/warehouse/bincontent/index.vue"),
            meta: {
              title: "Bin Content | Warehouse",
              requiredLogin: true,
              layout: "default",
              routeMap: "Warehouse.BinContent",
            },
          },
        ],
      },

      {
        path: "bookingmaterial",
        name: "Warehouse.BookingMaterial",
        component: { render: (h) => h("router-view") },
        meta: {
          title: "Booking Material | Warehouse",
          requiredLogin: true,
          routeMap: "Warehouse.BookingMaterial",
        },
        children: [
          {
            path: "/",
            name: "Warehouse.BookingMaterial",
            component: () => import("@/views/warehouse/bookingmaterial/index.vue"),
            meta: {
              title: "Booking Material | Warehouse",
              requiredLogin: true,
              layout: "default",
              routeMap: "Warehouse.BookingMaterial",
            },
          },
          {
            path: "edit",
            name: "Warehouse.BookingMaterial.Edit",
            component: () => import("@/views/warehouse/bookingmaterial/Edit.vue"),
            meta: {
              title: "Edit | Booking Material | Warehouse",
              requiredLogin: true,
              layout: "default",
              routeMap: "Warehouse.BookingMaterial.Edit",
            },
          },
        ],
      },
    ],
  },
  // Finance
  {
    path: "/finance",
    name: "Finance",
    // redirect: "",
    component: { render: (h) => h("router-view") },
    meta: {
      title: "Finance",
      requiredLogin: true,
      layout: "default",
      routeMap: "Finance",
    },
    children: [
      {
        path: "/finance/resumecontract",
        name: "Finance.ResumeContract",
        component: { render: (h) => h("router-view") },
        meta: {
          title: "Resume Contract | Finance",
          requiredLogin: true,
          layout: "default",
          routeMap: "Finance.ResumeContract",
        },
        children: [
          {
            path: "/",
            name: "Finance.ResumeContract",
            component: () => import("@/views/finance/resumecontract/index"),
            meta: {
              title: "Resume Contract | Finance",
              requiredLogin: true,
              layout: "default",
              routeMap: "Finance.ResumeContract",
            },
          },
          {
            path: "detail",
            name: "Finance.ResumeContract.Detail",
            component: () => import("@/views/finance/resumecontract/Detail"),
            meta: {
              title: "Detail | Resume Contract | Finance",
              requiredLogin: true,
              layout: "default",
              routeMap: "Finance.ResumeContract",
            },
          },
          {
            path: "detail/:id",
            name: "Finance.ResumeContract.Detail2",
            component: () => import("@/views/finance/resumecontract/Detail"),
            meta: {
              title: "Detail | Resume Contract | Finance",
              requiredLogin: true,
              layout: "default",
              routeMap: "Finance.ResumeContract",
            },
          },
          {
            path: "edit",
            name: "Finance.ResumeContract.Edit",
            component: () => import("@/views/finance/resumecontract/Edit"),
            meta: {
              title: "Edit | Resume Contract | Finance",
              requiredLogin: true,
              layout: "default",
              routeMap: "Finance.ResumeContract",
            },
          },
          {
            path: "edit/:id",
            name: "Finance.ResumeContract.Edit2",
            component: () => import("@/views/finance/resumecontract/Edit"),
            meta: {
              title: "Edit | Resume Contract | Finance",
              requiredLogin: true,
              layout: "default",
              routeMap: "Finance.ResumeContract",
            },
          },
          {
            path: "print-preview",
            name: "Finance.ResumeContract.PrintPreview",
            component: () => import("@/views/finance/resumecontract/PrintDocument"),
            meta: {
              title: "Print Preview | Resume Contract | Finance",
              requiredLogin: true,
              layout: "blank",
              routeMap: "Finance.ResumeContract",
            },
          },
        ],
      },
      {
        path: "/finance/addendum",
        name: "Finance.Addendum",
        component: { render: (h) => h("router-view") },
        meta: {
          title: "Addendum Contract | Finance",
          requiredLogin: true,
          layout: "default",
          routeMap: "Finance.Addendum",
        },
        children: [
          {
            path: "/",
            name: "Finance.Addendum",
            component: () => import("@/views/finance/addendum/index"),
            meta: {
              title: "Addendum Contract | Finance",
              requiredLogin: true,
              layout: "default",
              routeMap: "Finance.Addendum",
            },
          },
          {
            path: "detail",
            name: "Finance.Addendum.Detail",
            component: () => import("@/views/finance/addendum/Detail"),
            meta: {
              title: "Detail | Addendum Contract | Finance",
              requiredLogin: true,
              layout: "default",
              routeMap: "Finance.Addendum",
            },
          },
          {
            path: "detail/:id",
            name: "Finance.Addendum.Detail2",
            component: () => import("@/views/finance/addendum/Detail"),
            meta: {
              title: "Detail | Addendum Contract | Finance",
              requiredLogin: true,
              layout: "default",
              routeMap: "Finance.Addendum",
            },
          },
          {
            path: "edit",
            name: "Finance.Addendum.Edit",
            component: () => import("@/views/finance/addendum/Edit"),
            meta: {
              title: "Edit | Addendum Contract | Finance",
              requiredLogin: true,
              layout: "default",
              routeMap: "Finance.Addendum",
            },
          },
          {
            path: "edit/:id",
            name: "Finance.Addendum.Edit2",
            component: () => import("@/views/finance/addendum/Edit"),
            meta: {
              title: "Edit | Addendum Contract | Finance",
              requiredLogin: true,
              layout: "default",
              routeMap: "Finance.Addendum",
            },
          },
          {
            path: "print-preview",
            name: "Finance.Addendum.PrintPreview",
            component: () => import("@/views/finance/addendum/PrintDocument"),
            meta: {
              title: "Print Preview | Addendum Contract | Finance",
              requiredLogin: true,
              layout: "blank",
              routeMap: "Finance.Addendum",
            },
          },
        ],
      },
      {
        path: "/finance/baabdcheck",
        name: "Finance.BaabdCheck",
        component: { render: (h) => h("router-view") },
        meta: {
          title: "BA Checklist ABD | Finance",
          requiredLogin: true,
          layout: "default",
          routeMap: "Finance.BaabdCheck",
        },
        children: [
          {
            path: "/",
            name: "Finance.BaabdCheck",
            component: () => import("@/views/finance/baabdcheck/index"),
            meta: {
              title: "BA Checklist ABD | Finance",
              requiredLogin: true,
              layout: "default",
              routeMap: "Finance.BaabdCheck",
            },
          },
          {
            path: "detail",
            name: "Finance.BaabdCheck.Detail",
            component: () => import("@/views/finance/baabdcheck/Detail"),
            meta: {
              title: "Detail | BA Checklist ABD | Finance",
              requiredLogin: true,
              layout: "default",
              routeMap: "Finance.BaabdCheck",
            },
          },
          {
            path: "detail/:id",
            name: "Finance.BaabdCheck.Detail2",
            component: () => import("@/views/finance/baabdcheck/Detail"),
            meta: {
              title: "Detail | BA Checklist ABD | Finance",
              requiredLogin: true,
              layout: "default",
              routeMap: "Finance.BaabdCheck",
            },
          },
          {
            path: "edit",
            name: "Finance.BaabdCheck.Edit",
            component: () => import("@/views/finance/baabdcheck/Edit"),
            meta: {
              title: "Edit | BA Checklist ABD | Finance",
              requiredLogin: true,
              layout: "default",
              routeMap: "Finance.BaabdCheck",
            },
          },
          {
            path: "edit/:id",
            name: "Finance.BaabdCheck.Edit2",
            component: () => import("@/views/finance/baabdcheck/Edit"),
            meta: {
              title: "Edit | BA Checklist ABD | Finance",
              requiredLogin: true,
              layout: "default",
              routeMap: "Finance.BaabdCheck",
            },
          },
          {
            path: "print-preview",
            name: "Finance.BaabdCheck.PrintPreview",
            component: () => import("@/views/finance/baabdcheck/PrintDocument"),
            meta: {
              title: "Print Preview | BA Checklist ABD | Finance",
              requiredLogin: true,
              layout: "blank",
              routeMap: "Finance.BaabdCheck",
            },
          },
        ],
      },
      {
        path: "/finance/baabd",
        name: "Finance.Baabd",
        component: { render: (h) => h("router-view") },
        meta: {
          title: "BA Kronologis ABD | Finance",
          requiredLogin: true,
          layout: "default",
          routeMap: "Finance.Baabd",
        },
        children: [
          {
            path: "/",
            name: "Finance.Baabd",
            component: () => import("@/views/finance/baabd/index"),
            meta: {
              title: "BA Kronologis ABD | Finance",
              requiredLogin: true,
              layout: "default",
              routeMap: "Finance.Baabd",
            },
          },
          {
            path: "detail",
            name: "Finance.Baabd.Detail",
            component: () => import("@/views/finance/baabd/Detail"),
            meta: {
              title: "Detail | BA Kronologis ABD | Finance",
              requiredLogin: true,
              layout: "default",
              routeMap: "Finance.Baabd",
            },
          },
          {
            path: "detail/:id",
            name: "Finance.Baabd.Detail2",
            component: () => import("@/views/finance/baabd/Detail"),
            meta: {
              title: "Detail | BA Kronologis ABD | Finance",
              requiredLogin: true,
              layout: "default",
              routeMap: "Finance.Baabd",
            },
          },
          {
            path: "edit",
            name: "Finance.Baabd.Edit",
            component: () => import("@/views/finance/baabd/Edit"),
            meta: {
              title: "Edit | BA Kronologis ABD | Finance",
              requiredLogin: true,
              layout: "default",
              routeMap: "Finance.Baabd",
            },
          },
          {
            path: "edit/:id",
            name: "Finance.Baabd.Edit2",
            component: () => import("@/views/finance/baabd/Edit"),
            meta: {
              title: "Edit | BA Kronologis ABD | Finance",
              requiredLogin: true,
              layout: "default",
              routeMap: "Finance.Baabd",
            },
          },
          {
            path: "print-preview",
            name: "Finance.Baabd.PrintPreview",
            component: () => import("@/views/finance/baabd/PrintDocument"),
            meta: {
              title: "Print Preview | BA Kronologis ABD | Finance",
              requiredLogin: true,
              layout: "blank",
              routeMap: "Finance.Baabd",
            },
          },
        ],
      },
    ],
  },
  // Accounting
  {
    path: "/accounting",
    name: "Accounting",
    // redirect: "",
    component: { render: (h) => h("router-view") },
    meta: {
      title: "Accounting",
      requiredLogin: true,
      layout: "default",
      routeMap: "Accounting",
    },
    children: [
      {
        path: "/accounting/beritaacara",
        name: "Accounting.BeritaAcara",
        component: { render: (h) => h("router-view") },
        meta: {
          title: "Berita Acara | Accounting",
          requiredLogin: true,
          layout: "default",
          routeMap: "Accounting.BeritaAcara",
        },
        children: [
          {
            path: "/",
            name: "Accounting.BeritaAcara",
            component: () => import("@/views/accounting/beritaacara/index"),
            meta: {
              title: "Berita Acara | Accounting",
              requiredLogin: true,
              layout: "default",
              routeMap: "Accounting.BeritaAcara",
            },
          },
          {
            path: "edit",
            name: "Accounting.BeritaAcara.Edit",
            component: () => import("@/views/accounting/beritaacara/Edit"),
            meta: {
              title: "Edit | Berita Acara | Accounting",
              requiredLogin: true,
              layout: "default",
              routeMap: "Accounting.BeritaAcara",
            },
          },
          {
            path: "edit-accounting",
            name: "Accounting.BeritaAcara.EditAccounting",
            component: () => import("@/views/accounting/beritaacara/EditAccounting"),
            meta: {
              title: "Update Nilai Buku & Perolehan | Berita Acara | Accounting",
              requiredLogin: true,
              layout: "default",
              routeMap: "Accounting.BeritaAcara",
            },
          },
          {
            path: "detail",
            name: "Accounting.BeritaAcara.Detail",
            component: () => import("@/views/accounting/beritaacara/Detail"),
            meta: {
              title: "Detail | Berita Acara | Accounting",
              requiredLogin: true,
              layout: "default",
              routeMap: "Accounting.BeritaAcara",
            },
          },
          {
            path: "detail/:id",
            name: "Accounting.BeritaAcara.Detail2",
            component: () => import("@/views/accounting/beritaacara/Detail"),
            meta: {
              title: "Detail | Berita Acara | Accounting",
              requiredLogin: true,
              layout: "default",
              routeMap: "Accounting.BeritaAcara",
            },
          },
          {
            path: "printdocument",
            name: "Accounting.BeritaAcara.PrintDocument",
            component: () => import("@/views/accounting/beritaacara/PrintDocument"),
            meta: {
              title: "Print | Berita Acara | Accounting",
              requiredLogin: true,
              layout: "blank",
              routeMap: "Accounting.BeritaAcara",
            },
          },
        ],
      },
    ],
  },
  // Administration
  {
    path: "/purchasing",
    name: "Purchasing",
    // redirect: "",
    component: { render: (h) => h("router-view") },
    meta: {
      title: "Purchasing",
      requiredLogin: true,
      layout: "default",
      routeMap: "Purchasing",
    },
    children: [
      {
        path: "/purchasing/vendor",
        name: "Purchasing.Vendor",
        component: { render: (h) => h("router-view") },
        meta: {
          title: "Vendor | Purchasing",
          requiredLogin: true,
          layout: "default",
          routeMap: "Purchasing.Vendor",
        },
        children: [
          {
            path: "/",
            name: "Purchasing.Vendor",
            component: () => import("@/views/purchasing/vendor/index"),
            meta: {
              title: "Vendor | Purchasing",
              requiredLogin: true,
              layout: "default",
              routeMap: "Purchasing.Vendor",
            },
          },
          {
            path: "detail",
            name: "Purchasing.Vendor.Detail",
            component: () => import("@/views/purchasing/vendor/Detail"),
            meta: {
              title: "Detail | Vendor | Purchasing",
              requiredLogin: true,
              layout: "default",
              routeMap: "Purchasing.Vendor",
            },
          },
          {
            path: "edit",
            name: "Purchasing.Vendor.Edit",
            component: () => import("@/views/purchasing/vendor/Edit"),
            meta: {
              title: "Edit | Vendor | Purchasing",
              requiredLogin: true,
              layout: "default",
              routeMap: "Purchasing.Vendor",
            },
          },
        ],
      },
      {
        path: "/purchasing/tender",
        name: "Purchasing.Tender",
        component: { render: (h) => h("router-view") },
        meta: {
          title: "Tender | Purchasing",
          requiredLogin: true,
          layout: "default",
          routeMap: "Purchasing.Tender",
        },
        children: [
          {
            path: "/",
            name: "Purchasing.Tender",
            component: () => import("@/views/purchasing/tender/index"),
            meta: {
              title: "Tender | Purchasing",
              requiredLogin: true,
              layout: "default",
              routeMap: "Purchasing.Tender",
            },
          },
          {
            path: "detail",
            name: "Purchasing.Tender.Detail",
            component: () => import("@/views/purchasing/tender/Detail"),
            meta: {
              title: "Detail | Tender | Purchasing",
              requiredLogin: true,
              layout: "default",
              routeMap: "Purchasing.Tender",
            },
          },
          {
            path: "edit",
            name: "Purchasing.Tender.Edit",
            component: () => import("@/views/purchasing/tender/Edit"),
            meta: {
              title: "Edit | Tender | Purchasing",
              requiredLogin: true,
              layout: "default",
              routeMap: "Purchasing.Tender",
            },
          },
        ],
      },
      {
        path: "/purchasing/offer",
        name: "Purchasing.Offer",
        component: { render: (h) => h("router-view") },
        meta: {
          title: "Offer | Purchasing",
          requiredLogin: true,
          layout: "default",
          routeMap: "Purchasing.Offer",
        },
        children: [
          {
            path: "/",
            name: "Purchasing.Offer",
            component: () => import("@/views/purchasing/offer/index"),
            meta: {
              title: "Offer | Purchasing",
              requiredLogin: true,
              layout: "default",
              routeMap: "Purchasing.Offer",
            },
          },
          {
            path: "detail",
            name: "Purchasing.Offer.Detail",
            component: () => import("@/views/purchasing/offer/Detail"),
            meta: {
              title: "Detail | Offer | Purchasing",
              requiredLogin: true,
              layout: "default",
              routeMap: "Purchasing.Offer",
            },
          },
        ],
      },
      {
        path: "/purchasing/eprocannouncement",
        name: "Purchasing.EprocAnnouncement",
        component: { render: (h) => h("router-view") },
        meta: {
          title: "Eproc Announcement | Purchasing",
          requiredLogin: true,
          layout: "default",
          routeMap: "Purchasing.EprocAnnouncement",
        },
        children: [
          {
            path: "/",
            name: "Purchasing.EprocAnnouncement",
            component: () => import("@/views/purchasing/eprocannouncement/index"),
            meta: {
              title: "Eproc Announcement | Purchasing",
              requiredLogin: true,
              layout: "default",
              routeMap: "Purchasing.EprocAnnouncement",
            },
          },
          {
            path: "detail",
            name: "Purchasing.EprocAnnouncement.Detail",
            component: () => import("@/views/purchasing/eprocannouncement/Detail"),
            meta: {
              title: "Detail | Eproc Announcement | Purchasing",
              requiredLogin: true,
              layout: "default",
              routeMap: "Purchasing.EprocAnnouncement",
            },
          },
          {
            path: "edit",
            name: "Purchasing.EprocAnnouncement.Edit",
            component: () => import("@/views/purchasing/eprocannouncement/Edit"),
            meta: {
              title: "Edit | Eproc Announcement | Purchasing",
              requiredLogin: true,
              layout: "default",
              routeMap: "Purchasing.EprocAnnouncement",
            },
          },
        ],
      },
    ],
  },
  // Administration
  {
    path: "/administration",
    name: "Administration",
    // redirect: "",
    component: { render: (h) => h("router-view") },
    meta: {
      title: "Administration",
      requiredLogin: true,
      layout: "default",
      routeMap: "Administration",
    },
    children: [
      {
        path: "/administration/cpar",
        name: "Administration.CPAR",
        component: { render: (h) => h("router-view") },
        meta: {
          title: "CPAR | Administration",
          requiredLogin: true,
          layout: "default",
          routeMap: "Administration.CPAR",
        },
        children: [
          {
            path: "/",
            name: "Administration.CPAR",
            component: () => import("@/views/administration/cpar/index"),
            meta: {
              title: "CPAR | Administration",
              requiredLogin: true,
              layout: "default",
              routeMap: "Administration.CPAR",
            },
          },
          {
            path: "edit",
            name: "Administration.CPAR.Edit",
            component: () => import("@/views/administration/cpar/Edit"),
            meta: {
              title: "Edit | CPAR | Administration",
              requiredLogin: true,
              layout: "default",
              routeMap: "Administration.CPAR",
            },
          },
          {
            path: "set-pic",
            name: "Administration.CPAR.SetPIC",
            component: () => import("@/views/administration/cpar/SetPIC"),
            meta: {
              title: "Set PIC | CPAR | Administration",
              requiredLogin: true,
              layout: "default",
              routeMap: "Administration.CPAR",
            },
          },
          {
            path: "update-cause",
            name: "Administration.CPAR.UpdateCause",
            component: () => import("@/views/administration/cpar/UpdateCause"),
            meta: {
              title: "Update Cause | CPAR | Administration",
              requiredLogin: true,
              layout: "default",
              routeMap: "Administration.CPAR",
            },
          },
          {
            path: "update-correction",
            name: "Administration.CPAR.UpdateCorrection",
            component: () => import("@/views/administration/cpar/UpdateCorrection"),
            meta: {
              title: "Update Correction | CPAR | Administration",
              requiredLogin: true,
              layout: "default",
              routeMap: "Administration.CPAR",
            },
          },
          {
            path: "update-corrective",
            name: "Administration.CPAR.UpdateCorrective",
            component: () => import("@/views/administration/cpar/UpdateCorrective"),
            meta: {
              title: "Update Corrective | CPAR | Administration",
              requiredLogin: true,
              layout: "default",
              routeMap: "Administration.CPAR",
            },
          },
          {
            path: "detail",
            name: "Administration.CPAR.Detail",
            component: () => import("@/views/administration/cpar/Detail"),
            meta: {
              title: "Detail | CPAR | Administration",
              requiredLogin: true,
              layout: "default",
              routeMap: "Administration.CPAR",
            },
          },
          {
            path: "print-preview",
            name: "Administration.CPAR.PrintPreview",
            component: () => import("@/views/administration/cpar/PrintPreview"),
            meta: {
              title: "Print Preview | CPAR | Administration",
              requiredLogin: true,
              layout: "blank",
              routeMap: "Administration.CPAR",
            },
          },
          {
            path: "detail/:id",
            name: "Administration.CPAR.Detail2",
            component: () => import("@/views/administration/cpar/Detail"),
            meta: {
              title: "Detail | CPAR | Administration",
              requiredLogin: true,
              layout: "default",
              routeMap: "Administration.CPAR",
            },
          },
          {
            path: "printdocument",
            name: "Administration.CPAR.PrintDocument",
            component: () => import("@/views/administration/cpar/PrintDocument"),
            meta: {
              title: "Print | CPAR | Administration",
              requiredLogin: true,
              layout: "blank",
              routeMap: "Administration.CPAR",
            },
          },
        ],
      },

      {
        path: "/administration/mjo",
        name: "Administration.MJO",
        component: { render: (h) => h("router-view") },
        meta: {
          title: "MJO | Administration",
          requiredLogin: true,
          layout: "default",
          routeMap: "Administration.MJO",
        },
        children: [
          {
            path: "/",
            name: "Administration.MJO",
            component: () => import("@/views/administration/mjo/index"),
            meta: {
              title: "MJO | Administration",
              requiredLogin: true,
              layout: "default",
              routeMap: "Administration.MJO",
            },
          },

          {
            path: "edit",
            name: "Administration.MJO.Edit",
            component: () => import("@/views/administration/mjo/Edit"),
            meta: {
              title: "Edit | MJO | Administration",
              requiredLogin: true,
              layout: "default",
              routeMap: "Administration.MJO",
            },
          },

          {
            path: "detail",
            name: "Administration.MJO.Detail",
            component: () => import("@/views/administration/mjo/Detail"),
            meta: {
              title: "Detail | MJO | Administration",
              requiredLogin: true,
              layout: "default",
              routeMap: "Administration.MJO",
            },
          },

          {
            path: "detail/:id",
            name: "Administration.MJO.Detail",
            component: () => import("@/views/administration/mjo/Detail"),
            meta: {
              title: "Detail | MJO | Administration",
              requiredLogin: true,
              layout: "default",
              routeMap: "Administration.MJO",
            },
          },

          {
            path: "update",
            name: "Administration.MJO.Update",
            component: () => import("@/views/administration/mjo/Update"),
            meta: {
              title: "Update | MJO | Administration",
              requiredLogin: true,
              layout: "default",
              routeMap: "Administration.MJO",
            },
          },
        ],
      },
    ],
  },
  // HR
  {
    path: "/humanresource",
    name: "HumanResource",
    // redirect: "",
    component: { render: (h) => h("router-view") },
    meta: {
      title: "Human Resource",
      requiredLogin: true,
      layout: "default",
      routeMap: "HumanResource",
    },
    children: [
      {
        path: "/humanresource/department",
        name: "HumanResource.Department",
        component: { render: (h) => h("router-view") },
        meta: {
          title: "Department | Human Resource",
          requiredLogin: true,
          layout: "default",
          routeMap: "HumanResource.Department",
        },
        children: [
          {
            path: "/",
            name: "HumanResource.Department",
            component: () => import("@/views/humanresource/department/index"),
            meta: {
              title: "Department | Human Resource",
              requiredLogin: true,
              layout: "default",
              routeMap: "HumanResource.Department",
            },
          },
          {
            path: "add",
            name: "HumanResource.Department.Add",
            component: () => import("@/views/humanresource/department/Add"),
            meta: {
              title: "Add | Department | Human Resource",
              requiredLogin: true,
              layout: "default",
              routeMap: "HumanResource.Department",
            },
          },
          {
            path: "edit",
            name: "HumanResource.Department.Edit",
            component: () => import("@/views/humanresource/department/Edit"),
            meta: {
              title: "Edit | Department | Human Resource",
              requiredLogin: true,
              layout: "default",
              routeMap: "HumanResource.Department",
            },
          },
        ],
      },
      {
        path: "/humanresource/division",
        name: "HumanResource.Division",
        component: { render: (h) => h("router-view") },
        meta: {
          title: "Division | Human Resource",
          requiredLogin: true,
          layout: "default",
          routeMap: "HumanResource.Division",
        },
        children: [
          {
            path: "/",
            name: "HumanResource.Division",
            component: () => import("@/views/humanresource/division/index"),
            meta: {
              title: "Division | Human Resource",
              requiredLogin: true,
              layout: "default",
              routeMap: "HumanResource.Division",
            },
          },
          {
            path: "add",
            name: "HumanResource.Division.Add",
            component: () => import("@/views/humanresource/division/Add"),
            meta: {
              title: "Add | Division | Human Resource",
              requiredLogin: true,
              layout: "default",
              routeMap: "HumanResource.Division",
            },
          },
          {
            path: "edit",
            name: "HumanResource.Division.Edit",
            component: () => import("@/views/humanresource/division/Edit"),
            meta: {
              title: "Edit | Division | Human Resource",
              requiredLogin: true,
              layout: "default",
              routeMap: "HumanResource.Division",
            },
          },
        ],
      },
      {
        path: "/humanresource/employee",
        name: "HumanResource.Employee",
        component: { render: (h) => h("router-view") },
        meta: {
          title: "Employee | Human Resource",
          requiredLogin: true,
          layout: "default",
          routeMap: "HumanResource.Employee",
        },
        children: [
          {
            path: "/",
            name: "HumanResource.Employee",
            component: () => import("@/views/humanresource/employee/index"),
            meta: {
              title: "Employee | Human Resource",
              requiredLogin: true,
              layout: "default",
              routeMap: "HumanResource.Employee",
            },
          },
          {
            path: "add",
            name: "HumanResource.Employee.Add",
            component: () => import("@/views/humanresource/employee/Add"),
            meta: {
              title: "Add | Employee | Human Resource",
              requiredLogin: true,
              layout: "default",
              routeMap: "HumanResource.Employee",
            },
          },
          {
            path: "edit",
            name: "HumanResource.Employee.Edit",
            component: () => import("@/views/humanresource/employee/Edit"),
            meta: {
              title: "Edit | Employee | Human Resource",
              requiredLogin: true,
              layout: "default",
              routeMap: "HumanResource.Employee",
            },
          },
        ],
      },
    ],
  },
  // Setting
  {
    path: "/setting",
    name: "Setting",
    redirect: "/setting/setting-general",
    component: { render: (h) => h("router-view") },
    meta: {
      title: "Setting",
      requiredLogin: true,
      layout: "default",
      routeMap: "Setting",
    },
    children: [
      {
        path: "setting-general",
        name: "Setting.General",
        component: { render: (h) => h("router-view") },
        meta: {
          title: "General | Setting",
          requiredLogin: true,
          layout: "default",
          routeMap: "Setting.General",
        },
        children: [
          {
            path: "/",
            name: "Setting.General",
            component: () => import("@/views/setting/general/index"),
            meta: {
              title: "General | Setting",
              requiredLogin: true,
              layout: "default",
              routeMap: "Setting.General",
            },
          },
          {
            path: "module-edit-user",
            name: "Setting.General.ModuleEdit",
            component: () => import("@/views/setting/general/ModuleEdit"),
            meta: {
              title: "Edit Module | General | Setting",
              requiredLogin: true,
              layout: "default",
              routeMap: "Setting.General",
            },
          },
          {
            path: "data-access-edit",
            name: "Setting.General.DataAccessEdit",
            component: () => import("@/views/setting/general/DataAccessEdit"),
            meta: {
              title: "Edit Data Access | General | Setting",
              requiredLogin: true,
              layout: "default",
              routeMap: "Setting.General",
            },
          },
        ],
      },
      {
        path: "user",
        name: "Setting.User",
        component: { render: (h) => h("router-view") },
        meta: {
          title: "User | Setting",
          requiredLogin: true,
          layout: "default",
          routeMap: "Setting.User",
        },
        children: [
          {
            path: "/",
            name: "Setting.User",
            component: () => import("@/views/setting/user/index"),
            meta: {
              title: "User | Setting",
              requiredLogin: true,
              layout: "default",
              routeMap: "Setting.User",
            },
          },
          {
            path: "edit",
            name: "Setting.User.Edit",
            component: () => import("@/views/setting/user/edit"),
            meta: {
              title: "Edit | Setting",
              requiredLogin: true,
              layout: "default",
              routeMap: "Setting.User.Edit",
            },
          },
        ],
      },
      {
        path: "mailoutbox",
        name: "Setting.MailOutbox",
        component: { render: (h) => h("router-view") },
        meta: {
          title: "MailOutbox | Setting",
          requiredLogin: true,
          layout: "default",
          routeMap: "Setting.MailOutbox",
        },
        children: [
          {
            path: "/",
            name: "Setting.MailOutbox",
            component: () => import("@/views/setting/mailoutbox/index"),
            meta: {
              title: "MailOutbox | Setting",
              requiredLogin: true,
              layout: "default",
              routeMap: "Setting.MailOutbox",
            },
          },
          {
            path: "edit",
            name: "Setting.MailOutbox.Edit",
            component: () => import("@/views/setting/mailoutbox/Edit"),
            meta: {
              title: "Edit | MailOutbox | Setting",
              requiredLogin: true,
              layout: "default",
              routeMap: "Setting.MailOutbox.Edit",
            },
          },
        ],
      },
      {
        path: "module",
        name: "Setting.Module",
        component: { render: (h) => h("router-view") },
        meta: {
          title: "Module | Setting",
          requiredLogin: true,
          layout: "default",
          routeMap: "Setting.Module",
        },
        children: [
          {
            path: "/",
            name: "Setting.Module",
            component: () => import("@/views/setting/module/index"),
            meta: {
              title: "Module | Setting",
              requiredLogin: true,
              layout: "default",
              routeMap: "Setting.Module",
            },
          },
          {
            path: "edit",
            name: "Setting.Module.Edit",
            component: () => import("@/views/setting/module/edit"),
            meta: {
              title: "Edit | Module | Setting",
              requiredLogin: true,
              layout: "default",
              routeMap: "Setting.Module.Edit",
            },
          },
        ],
      },
      {
        path: "/setting/registeredcampaign",
        name: "Setting.RegisteredCampaign",
        component: () => import("@/views/setting/RegisteredCampaign.vue"),
        meta: {
          title: "Registered Campaign | Setting",
          requiredLogin: true,
          layout: "default",
          routeMap: "Setting.RegisteredCampaign",
        },
      },
      {
        path: "menu",
        name: "Setting.Menu",
        component: { render: (h) => h("router-view") },
        meta: {
          title: "Menu | Setting",
          requiredLogin: true,
          layout: "default",
          routeMap: "Setting.Menu",
        },
        children: [
          {
            path: "/",
            name: "Setting.Menu",
            component: () => import("@/views/setting/menu/index"),
            meta: {
              title: "Menu | Setting",
              requiredLogin: true,
              layout: "default",
              routeMap: "Setting.Menu",
            },
          },
        ],
      },
      {
        path: "approvaltemplate",
        name: "Setting.ApprovalTemplate",
        component: { render: (h) => h("router-view") },
        meta: {
          title: "Approval Template | Setting",
          requiredLogin: true,
          layout: "default",
          routeMap: "Setting.ApprovalTemplate",
        },
        children: [
          {
            path: "/",
            name: "Setting.ApprovalTemplate",
            component: () => import("@/views/setting/approvaltemplate/index"),
            meta: {
              requiredLogin: true,
              layout: "default",
              routeMap: "Setting.ApprovalTemplate",
            },
          },
        ],
      },
      {
        path: "approvalmodifier",
        name: "Setting.ApprovalModifier",
        component: { render: (h) => h("router-view") },
        meta: {
          title: "Approval Modifier | Setting",
          requiredLogin: true,
          layout: "default",
          routeMap: "Setting.ApprovalModifier",
        },
        children: [
          {
            path: "/",
            name: "Setting.ApprovalModifier",
            component: () => import("@/views/setting/approvalmodifier/index"),
            meta: {
              title: "Approval Modifier | Setting",
              requiredLogin: true,
              layout: "default",
              routeMap: "Setting.ApprovalModifier",
            },
          },
        ],
      },
      {
        path: "cparsource",
        name: "Setting.CPARSource",
        component: { render: (h) => h("router-view") },
        meta: {
          title: "CAPA Source | Setting",
          requiredLogin: true,
          layout: "default",
          routeMap: "Setting.CPARSource",
        },
        children: [
          {
            path: "/",
            name: "Setting.CPARSource",
            component: () => import("@/views/setting/cparsource/index"),
            meta: {
              title: "CAPA Source | Setting",
              requiredLogin: true,
              layout: "default",
              routeMap: "Setting.CPARSource",
            },
          },
          {
            path: "add",
            name: "Setting.CPARSource.Add",
            component: () => import("@/views/setting/cparsource/Add"),
            meta: {
              title: "Add | CAPA Source | Setting",
              requiredLogin: true,
              layout: "default",
              routeMap: "Setting.CPARSource",
            },
          },
          {
            path: "edit",
            name: "Setting.CPARSource.Edit",
            component: () => import("@/views/setting/cparsource/Edit"),
            meta: {
              title: "Edit | CAPA Source | Setting",
              requiredLogin: true,
              layout: "default",
              routeMap: "Setting.CPARSource",
            },
          },
        ],
      },
      {
        path: "cparcategory",
        name: "Setting.CPARCategory",
        component: { render: (h) => h("router-view") },
        meta: {
          title: "CAPA Category | Setting",
          requiredLogin: true,
          layout: "default",
          routeMap: "Setting.CPARCategory",
        },
        children: [
          {
            path: "/",
            name: "Setting.CPARCategory",
            component: () => import("@/views/setting/cparcategory/index"),
            meta: {
              title: "CAPA Category | Setting",
              requiredLogin: true,
              layout: "default",
              routeMap: "Setting.CPARCategory",
            },
          },
          {
            path: "add",
            name: "Setting.CPARCategory.Add",
            component: () => import("@/views/setting/cparcategory/Add"),
            meta: {
              title: "Add | CAPA Category | Setting",
              requiredLogin: true,
              layout: "default",
              routeMap: "Setting.CPARCategory",
            },
          },
          {
            path: "edit",
            name: "Setting.CPARCategory.Edit",
            component: () => import("@/views/setting/cparcategory/Edit"),
            meta: {
              title: "Edit | CAPA Category | Setting",
              requiredLogin: true,
              layout: "default",
              routeMap: "Setting.CPARCategory",
            },
          },
        ],
      },
    ],
  },
  // Account
  {
    path: "/account",
    name: "Account",
    component: () => import("@/views/Account.vue"),
    meta: {
      title: "Account",
      requiredLogin: true,
      layout: "default",
    },
  },
  // MaintenanceMode
  {
    path: "/maintenance",
    name: "MaintenanceMode",
    component: () => import("@/views/MaintenanceMode.vue"),
    meta: {
      title: "Maintenance",
      requiredLogin: false,
      layout: "blank",
    },
  },
  // Login
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    meta: {
      title: "Login",
      requiredLogin: false,
      layout: "login",
    },
  },
  // Forgot
  {
    path: "/forgot",
    name: "Forgot",
    component: () => import("@/views/Forgot.vue"),
    meta: {
      title: "Forgot Password",
      requiredLogin: false,
      layout: "login",
    },
  },
  // Reset Password
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("@/views/ResetPassword.vue"),
    meta: {
      title: "Reset Password",
      requiredLogin: false,
      layout: "login",
    },
  },
  // Register
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/Register.vue"),
    meta: {
      title: "Register",
      requiredLogin: false,
      layout: "login",
    },
  },
  // About
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: {
      title: "About",
      requiredLogin: true,
      layout: "default",
    },
  },
  // Generate Session
  {
    path: "/generatesession",
    name: "GenerateSession",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/GenerateSession.vue"),
    meta: {
      title: "About",
      requiredLogin: false,
      layout: "blank",
    },
  },
  // Error: 401
  {
    path: "/401",
    name: "401",
    component: () => import("@/views/401"),
    meta: {
      title: "401",
      requiredLogin: false,
      layout: "blank",
    },
  },
  // IF NOT FOUND Error : 404
  {
    path: "*",
    redirect: "404",
  },
  // Error : 404
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/404"),
    meta: {
      title: "404",
      requiredLogin: false,
      layout: "blank",
    },
  },

  {
    path: "/comment/print",
    name: "CommentPrint",
    component: () => import("@/views/helper/comment/PrintPreview"),
    meta: {
      title: "Print",
      requiredLogin: true,
      layout: "blank",
    },
  },
  // Print Comment
  // Test Page
  // {
  //   path: "/test",
  //   name: "test",
  //   component: () => import("@/views/Test"),
  //   meta: {
  //     title: 'Test Page',
  //     requiredLogin: false,
  //     layout: "blank",
  //   },
  // },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

function isLoggedIn() {
  let isLoggedIn = localStorage.getItem("BPW_STORAGE_isLoggedIn");

  if (isLoggedIn === "true") {
    return true;
  }
  return false;
}

// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from, next) => {
  if (typeof to.meta.title === 'undefined') {
    document.title = 'BPW APP'
  } else {
    document.title = to.meta.title
  }

  if (to.meta.requiredLogin == true) {
    if (isLoggedIn()) {
      // if(to.name === "Login") {
      //   next('/dashboard')
      // }else{
      next();
      // }
    } else {
      if (typeof to.fullPath == "undefined" || to.fullPath == "/") {
        next("/login");
      } else {
        next("/login?redirect=" + to.fullPath);
      }
    }
  } else {
    // if(to.name === "Login") {
    //   if (isLoggedIn()) {
    //     next('/dashboard')
    //   }else{
    //     next()
    //   }
    // }else{
    next();
    // }
  }
});

export default router;
