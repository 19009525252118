<!-- eslint-disable vue/no-unused-components -->
<template>
  <div ref="refParent">
    <v-card :elevation="0">
      <v-card-text>
        <div class="d-flex">
          <v-textarea v-model="comment_body" ref="refCommentBody" @input="onInput" :disabled="loading_add" outlined class="fill-height" rows="2" hide-details label="Write Comment Here.."></v-textarea>
          <v-btn text class="px-0 mx-0" small @click="saveComment()">
            <v-icon>mdi-send</v-icon>
          </v-btn>
        </div>
        <!-- Menu untuk daftar username -->
        <v-menu v-model="showList" :close-on-content-click="false" :offset-y="false" :nudge-bottom="5" transition="scale-transition" :position-x="menuPosition.x" :position-y="menuPosition.y" absolute>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on"></div>
          </template>

          <v-list dense>
            <v-list-item v-for="(user, index) in filteredUsers" :key="index" @click="addMention(user)" class="py-0" style="min-height: 25px">
              <v-list-item-title class="py-0">@{{ user }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <p class="mt-2 mb-2 text-caption">Type @ in front of the name of the person you want to mention, then select that person from the menu that appears.</p>
      </v-card-text>

      <v-card-text>
        <div class="d-flex">
          <span class="text--caption">List Comments</span>
          <v-spacer></v-spacer>
          <v-btn
            @click="
              datatable.sortDesc = !datatable.sortDesc;
              getData(true);
            "
            small
            text
            class="float-right"
          >
            <v-icon v-if="!datatable.sortDesc">mdi-sort-descending</v-icon>
            <v-icon v-else>mdi-sort-ascending</v-icon>
          </v-btn>
        </div>
      </v-card-text>

      <v-data-table :loader-height="0" :options.sync="datatable_options" :loading="datatable.loading" :loading-text="datatable.loadingText" :headers="datatable.columns" :items="datatable.data" :search="search" :server-items-length="datatable.totalRows" :items-per-page="datatable_options.itemsPerPage" :hide-default-header="true" dense class="py-3 my-0 commenttable" hide-default-footer item-class="nohover" @page-count="pageCount = $event" :sort-by="datatable.sortBy" :sort-desc="datatable.sortDesc">
        <template v-slot:[`item.id`]="{ item }">
          <app-comment-card :data="item" @after-delete="getData(true)" @after-edit="getData(true)" @after-reply="getData(true)"></app-comment-card>
        </template>

        <template #loading>
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </template>

        <v-alert slot="no-results" :value="true" color="error" icon="warning">Comment not found!</v-alert>
      </v-data-table>
      <div class="text-right pt-2 mx-auto" style="max-width: 600px">
        <v-pagination circle v-model="datatable_options.page" :total-visible="7" :length="totalPage"></v-pagination>
      </div>
    </v-card>
  </div>
</template>

<script>
// import CommentCard from './CommentCard';
export default {
  props: {
    document_id: {
      type: Number,
      default: 0,
    },
    source_document: {
      type: String,
      default: "",
    },
    document_no_: {
      type: String,
      default: "",
    },
    elevation: {
      type: Number,
      default: 1,
    },
  },
  components: {
    // CommentAdd,
    // eslint-disable-next-line vue/no-unused-components
    // CommentCard,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      comments: [],
      limit: 5,
      dialog_add: false,
      search: "",
      datatable_options: {
        page: 1,
        itemsPerPage: 5,
      },
      itemsPerPageOption: [5, 20, 50, 100, 500],
      totalPage: 0,
      datatable: {
        loading: null,
        loadingText: "Loading... Please wait...",
        totalRows: 0,
        columns: [{ text: "id", value: "id", sortable: false }],
        data: [],
        sortBy: "id",
        sortDesc: false,
      },

      loading_add: false,
      comment_body: "",

      users: [], // Daftar username
      filteredUsers: [], // Hasil filter berdasarkan input
      showList: false, // Kontrol untuk menu
      menuPosition: { x: 0, y: 0 }, // Posisi menu
    };
  },
  computed: {
    comments_limit() {
      var cl = [];
      for (var i = 0; i < this.limit; i++) {
        cl.push(this.comments[i]);
      }
      return cl;
    },

    _document_id() {
      return this.document_id;
    },

    _source_document() {
      return this.source_document;
    },

    _document_no_() {
      return this.document_no_;
    },
  },
  watch: {
    document_id(val) {
      if (val) {
        this.getData(true);
      }
    },
    datatable_options: {
      handler() {
        this.getData();
      },
      deep: true,
    },

    search: {
      handler() {
        this.datatable_options.page = 1;
        this.getData();
      },
      deep: true,
    },
  },
  methods: {
    refresh() {
      this.datatable.data = [];
      this.datatable.totalRows = 0;
      this.getData(true);
    },

    async getData(refresh) {
      const uri = "comments/data";
      if (refresh) this.AxiosStorageRemove("POST", uri);

      if (typeof this._document_id === "undefined" || this._document_id == 0 || isNaN(this._document_id)) return;

      this.datatable.loading = true;

      var formData = this.dataTableFormData(this.datatable_options, this.search, {
        document_id: this._document_id,
        source_document: this._source_document,
        // document_no_: this._document_no_,
      });

      await this.$axios
        .post(uri, formData)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.data = resData.data.results;
            this.datatable.totalRows = resData.data.paging.total_rows;
            this.totalPage = resData.data.paging.total_page;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable.loading = false;
        });
    },
    async saveComment() {
      if (this.comment_body.toString().trim().length === 0) return this.showAlert("error", "Can't empty!");
      this.loading_add = true;
      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append("document_id", this.document_id);
      formData.append("source_document", this.source_document);
      // formData.append("document_no_", this.document_no_);
      formData.append("comment_body", this.comment_body);
      await this.$axios
        .post("comments/save", formData)
        .then((res) => {
          this.loading_add = false;
          this.showLoadingOverlay(false);
          this.showAlert(res.data.status, res.data.message);
          this.comment_body = "";

          this.getData(true);
        })
        .catch((error) => {
          this.loading_add = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
          this.getData(true);
        });
    },

    onInput() {
      const textarea = this.$refs.refCommentBody.$el.querySelector("textarea");
      // Cari posisi '@' terakhir
      const mentionIndex = this.comment_body.lastIndexOf("@");
      if (mentionIndex !== -1) {
        // Ambil substring setelah '@'
        const search = this.comment_body.slice(mentionIndex + 1).trim();
        if (search.length == 0) {
          this.showList = false; // Sembunyikan menu jika '@' dihapus
          return;
        }

        // Filter daftar username
        // this.filteredUsers = this.users.filter((user) =>
        //   user.toLowerCase().startsWith(search.toLowerCase())
        // ).slice(0, 10);
        // this.findUser(search);

        this.filteredUsers = [];
        const config = {
          params: {
            search: search,
          },
        };
        this.$axios
          .get("comments/users", config)
          .then((res) => {
            if (res.data.data.length > 0) {
              this.filteredUsers = res.data.data;
            }

            // Jika ada hasil, tampilkan menu dan hitung posisinya
            if (this.filteredUsers.length > 0) {
              this.showList = true;

              // Hitung posisi kursor
              const coordinates = this.getCaretCoordinates(textarea, mentionIndex + 1);
              const textareaRect = textarea.getBoundingClientRect();

              // Perhatikan posisi relatif ke dialog
              // const dialogRect = this.$refs.refParent.$el.getBoundingClientRect();

              // Set posisi menu relatif terhadap textarea
              // this.menuPosition.x = textareaRect.left - dialogRect.left + coordinates.left;
              // this.menuPosition.y = textareaRect.top - dialogRect.top + coordinates.top + 15;

              this.menuPosition.x = textareaRect.left + coordinates.left;
              this.menuPosition.y = textareaRect.top + coordinates.top + 15;
            } else {
              this.showList = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.showList = false; // Sembunyikan menu jika '@' dihapus
      }

      console.log(this.menuPosition);
    },
    addMention(user) {
      // Tambahkan username ke dalam input
      const mentionIndex = this.comment_body.lastIndexOf("@");
      if (mentionIndex !== -1) {
        this.comment_body = this.comment_body.slice(0, mentionIndex) + "@" + user + " "; // Ganti '@' dengan username
        this.showList = false; // Sembunyikan menu
      }
    },
    getCaretCoordinates(element, position) {
      // Helper untuk menghitung posisi kursor di textarea
      const div = document.createElement("div");
      const style = window.getComputedStyle(element);

      [...style].forEach((prop) => {
        div.style[prop] = style[prop];
      });

      div.style.position = "absolute";
      div.style.visibility = "hidden";
      div.style.whiteSpace = "pre-wrap";
      div.style.wordWrap = "break-word";

      const span = document.createElement("span");
      span.textContent = element.value.substring(0, position);
      div.appendChild(span);

      document.body.appendChild(div);

      const coordinates = {
        left: span.offsetWidth,
        top: span.offsetHeight,
      };

      document.body.removeChild(div);
      return coordinates;
    },
    async findUser(search = "") {
      this.filteredUsers = [];
      const config = {
        params: {
          search: search,
        },
      };
      await this.$axios
        .get("comments/users", config)
        .then((res) => {
          if (res.data.data.length > 0) {
            this.filteredUsers = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  mounted() {
    this.comment_body = "";
  },
};
</script>

<style scoped>
::v-deep .v-data-table__wrapper tbody tr:hover {
  background: #fff !important;
}

::v-deep .v-data-table__wrapper tbody td {
  border: none !important;
}
</style>
