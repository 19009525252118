const config = {
  // Live
  apiUrl: "https://api.betonperkasa.com/bpw/",
  base_url: "https://bpw.betonperkasa.com/",
  base_url_domain: "bpw.betonperkasa.com",

  // Development
  // apiUrl: process.env.VUE_APP_API_URL,
  // apiUrl: 'http://192.168.2.18:8080/bpw/',
  // base_url: 'http://192.168.2.18:8081/',
  // base_url_domain: '192.168.2.18:8081',
  // apiUrl: "https://api.betonperkasa.local/bpw/",
  // base_url: "https://bpw.betonperkasa.local/",
  // base_url_domain: "bpw.betonperkasa.local",

  notificationPlugin: "awn", // awn|notify

  notificationRefreshTime: 5000,

  axiosErrorResponseMessage: "Oops.. Something went wrong, please contact IT Administrator.",
};

export default config;
