<template>
  <v-menu offset-y right bottom left>
    <template v-slot:activator="{ attrs, on }">
      <v-avatar v-bind="attrs" v-on="on" size="36" class="mx-1">
        <v-img :src="resourceUrl($store.state.loginInfo.profileImage)"></v-img>
      </v-avatar>
    </template>

    <v-card min-width="250">
      <v-card-text class="px-0 py-0">
        <v-card-text class="text-center">
          <div class="mx-auto d-block mb-2">
            <v-avatar style="cursor: pointer" size="72">
              <v-img :src="imageUrl"></v-img>
            </v-avatar>
          </div>
          <span class="d-block mx-auto">{{ username }}</span>
          <span class="d-block mx-auto">{{ fullName }}</span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <a class="d-block" @click="$refs.fileupload.click()"><v-icon small>mdi-upload</v-icon> Upload Sign File</a>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="redirect('Account')" color="primary">Account</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="signOut()" color="primary">Logout</v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
    <input type="file" ref="fileupload" name="fileupload" style="display: none" v-on:change="changeImage" accept="image/*" />
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      fileupload: "",
      loading: false,
    };
  },
  computed: {
    imageUrl() {
      return this.$store.state.loginInfo.profileImage ? this.$store.state.loginInfo.profileImage : "";
    },
    username() {
      return this.$store.state.loginInfo.username;
    },
    fullName() {
      return this.$store.state.loginInfo.fullName;
    },
  },
  methods: {
    async changeImage() {
      this.loading = true;
      this.fileupload = this.$refs.fileupload.files[0];
      if (this.fileupload === "") return;

      this.showLoadingOverlay(true);

      let formData = new FormData();
      formData.append("sign_img", this.fileupload);
      await this.$axios
        .post("account/update-sign-image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);
          } else {
            this.showAlert(resData.status, resData.message);
          }

          this.getData(true);
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
          console.log("FAILURE!!");
        });
    },
  },
};
</script>
