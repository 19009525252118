<template>
  <div>
    <v-dialog v-bind="$attrs" v-on="$listeners" v-model="internalValue" :width="width" :fullscreen="internalFullscreen" scrollable>
      <v-card :loading="loading">
        <v-toolbar elevation="0" outlined class="text-h5 grey lighten-3" dense>
          <v-toolbar-title v-if="loading"> <v-progress-circular indeterminate color="primary" :size="20" :width="2" class="mr-3"></v-progress-circular> Loading... </v-toolbar-title>
          <v-toolbar-title v-else>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <slot name="top-actions"></slot> -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="light" small v-bind="attrs" v-on="on" @click="fullscreenDialog()" icon v-if="!hideFullScreenButton">
                <v-icon v-if="!internalFullscreen">mdi-fullscreen</v-icon>
                <v-icon v-else>mdi-fullscreen-exit</v-icon>
              </v-btn>
            </template>
            <span v-if="!internalFullscreen">Fullscreen</span>
            <span v-else>Exit Fullscreen</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="light" class="mr-1" small v-bind="attrs" v-on="on" @click="closeDialog()" icon v-if="!hideCloseButton">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-toolbar>
        <v-sheet class="d-flex flex-row flex-wrap align-center py-1 px-1" elevation="1" outlined>
          <app-button @click="closeDialog()" title="Close Dialog" mdi-icon="mdi-close"></app-button>
          <v-divider vertical class="mx-2"></v-divider>
          <app-button @click="fullscreenDialog()" title="Fullscreen" mdi-icon="mdi-fullscreen"></app-button>
          <!-- Button Refresh -->
          <app-button @click="getData(true)" title="Refresh Data" mdi-icon="mdi-refresh" :loading="datatable.loading"></app-button>
          <slot name="toolbar"></slot>
          <v-spacer></v-spacer>
          <!-- <v-divider vertical class="mx-2"></v-divider> -->
          <div style="max-width:300px">
            <v-text-field
            v-model="datatable.search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            clearable
            outlined
            dense
            @keyup.enter="getData(true)"
            @click:clear="getData(true)"></v-text-field>
          </div>
        </v-sheet>
        <v-card :min-height="internalFullscreen ? minHeightContent : null" style="overflow-y: auto" class="rounded-0 grey lighten-2" elevation="0">
          <v-card-text class="ma-0 pa-0">
            <v-data-table
              :headers="datatable.headers"
              :items="datatable.items"
              :search="datatable.search"
              :loading="datatable.loading"
              :options.sync="internalOptions" 
              :server-items-length="paging.total_results" 
              @page-count="pageCount = $event"
              :item-class="() => 'clickable-row'"
              dense
              @click:row="onClickRow"
            ></v-data-table>
            <!-- <app-data-table
              :uri="datatable.uri"
              :headers="datatable.headers"
              @click:row.native="rowDoubleClicked"
              hideItemsPerPageOption
              :itemsPerPage="10"
              :item-class="() => 'clickable-row'">
              <slot></slot>
            </app-data-table> -->
          </v-card-text>
        </v-card>
        <v-divider></v-divider>
        <v-card-actions>
          <slot name="actions"></slot>
          <v-spacer></v-spacer>
          <slot name="actions-right"></slot>
          <v-btn @click="closeDialog()" v-if="!hideCloseButton">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  inheritAttrs: false, // Supaya $attrs tidak diteruskan otomatis ke root element
  props: {
    value: {
      // Menggunakan prop 'value' karena Vue 2 menggunakan v-model dengan prop 'value'
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Search Dialog",
    },
    width: {
      type: String,
      default: "1200px",
    },
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
    hideFullScreenButton: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    callback: null,
    loading: null,
    headers: {
      type: Array,
      default: () => [],
    },
    uri: {
      type: String,
      default: "",
    },
    filterData: {
      type: Object,
      default: () => {
        return {}
      },
    },
    customParams: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      internalValue: this.value, // Menyinkronkan value dengan internalValue
      internalFullscreen: this.fullscreen,
      minHeightContent: "",
      internalTitle: "",
      internalHeaders: this.headers,
      datatable: {
        uri: this.uri,
        search: "",
        headers: this.headers,
        items: [],
        filters: [],
        loading: false,
      },
      internalOptions: {
        itemsPerPage: 5,
      },
      paging: {
        limit: 0,
        page: 0,
        offset: 0,
        total_page: 0,
        total_rows: 0,
        total_results: 0
      },
      internalFilterData: this.filterData,
      internalcustomParams: this.customParams,
    };
  },
  watch: {
    value(val) {
      this.internalValue = val;
    },
    internalValue(val) {
      this.$emit("input", val);

      if (val == false) {
        if (typeof this.callback == "function") this.callback();
      }
    },
    internalFullscreen(val) {
      this.$emit("fullscreen", val);

      if (val == true) {
        const viewportHeight = document.documentElement.clientHeight;
        this.minHeightContent = viewportHeight - 148;
      }
    },
    internalHeaders(val){
      this.datatable.headers = [
        {
          text: "#",
          sortable: false,
          value: null,
          width: "50px",
        },
        ...val,
      ];
    },
    // watch datatable.search
    // eslint-disable-next-line no-unused-vars
    "datatable.search": {
      handler() {
        this.getData();
      },
      deep: true,
    },
    
    internalOptions: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
  methods: {
    closeDialog() {
      this.internalValue = false; // Menutup dialog dan mengupdate internal value
    },
    fullscreenDialog() {
      this.internalFullscreen = !this.internalFullscreen;
    },
    // eslint-disable-next-line no-unused-vars
    onClickRow(item, slot) {
      console.log("Row diklik:", item);
      this.$emit("row-selected", item);
    },
    rowDoubleClicked(event) {
      const rowIndex = event.target.closest("tr").rowIndex - 1;
      const item = this.items[rowIndex];
      this.$emit("row-selected", item);
      console.log("Row double clicked:", item);
    },
    // get data

    getData(refresh) {
      if (refresh) this.AxiosStorageRemove("POST", this.uri);

      this.internalRefresh = true;
      this.datatable.loading = true;

      var formData = this.dataTableFormData(this.internalOptions, this.datatable.search, this.internalFilterData, this.internalcustomParams);
      this.$axios
        .post(this.datatable.uri, formData)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.items = resData.data.results;
            this.paging = resData.data.paging;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
          this.internalRefresh = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable.loading = false;
          this.internalRefresh = false;
        });
    },
  },
  mounted(){
    this.getData(true);
  }
};
</script>

<style>
/* Tambahkan CSS untuk mengubah kursor */
.clickable-row {
  cursor: pointer;
}
</style>
