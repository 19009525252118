"use strict";

import Vue from "vue";
import axios from "axios";
import AxiosStorage from "axios-storage";
import config2 from "@/constants/config";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// AxiosStorage set global config
AxiosStorage.config({
  storagePrefix: "_bpwStorage_",
  storageMode: "sessionStorage",
  deleteOnExpire: "aggressive",
  maxAge: 2 * 60 * 1000,
});

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  timeout: 60 * 1000, // Timeout
  withCredentials: true, // Check cross-site Access-Control

  adapter: AxiosStorage.adapter,
  baseURL: config2.apiUrl,
  // baseURL: 'http://192.168.2.18:8089/bpw/',
  // baseURL: 'https://api.betonperkasa.com/bpw/',
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent

    var apiToken = localStorage.getItem("BPW_STORAGE_apiToken");

    if (apiToken) {
      config.headers.Authorization = "Bearer " + localStorage.getItem("BPW_STORAGE_apiToken");
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error

    // console.log(error.response.status);
    // if(error.response.status == 403){
    //   window.location.assign("/403");
    // }

    if (error.response.status == 401) {
      window.location.replace("/401");
    }

    return Promise.reject(error);
  }
);

// eslint-disable-next-line no-unused-vars
Plugin.install = function (Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
