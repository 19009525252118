<template>
  <v-text-field v-bind="$attrs" :value="displayValue" :type="displayType" @input="onInput" @focus="onFocus" @blur="onBlur"></v-text-field>
</template>

<script>
export default {
  name: "AppTextField",
  inheritAttrs: false,
  props: {
    value: [String, Number],
    decimalPlaces: {
      type: Number,
      default: 2,
    },
    type: {
      type: String,
      default: "text",
    },
  },
  data() {
    return {
      isFocused: false,
      internalValue: this.value,
      internalType: this.type,
      // displayType: '',
    };
  },
  watch: {
    value(val) {
      this.internalValue = val;
    },
    internalValue(val) {
      this.$emit("input", val);
    },
    type(val) {
      if (val === "number") {
        this.internalType = "text";
        console.log("internalType", this.internalType);
        
      } else {
        this.internalType = val;
        console.log("internalType", this.internalType);
      }
    },
    // internalType(val) {
    //   if (val === "number") {
    //     this.displayType = "text";
    //   } else {
    //     this.displayType = val;
    //   }
    // },
  },
  computed: {
    displayValue() {
      if (this.isFocused) {
        return this.internalValue;
      }
      return this.formatNumber(this.internalValue);
    },
    displayType(){
      return (this.internalType === "number") ? "text" : this.internalType;
    }
  },
  methods: {
    onInput(val) {
      this.internalValue = val;
    },
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = false;
    },
    numberFormat(number, decimals, dec_point, thousands_sep) {
      if (typeof number === "undefined" || number === null || isNaN(number)) {
        return "";
      }
      // Strip all characters but numerical ones.
      number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
      var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
        dec = typeof dec_point === "undefined" ? "." : dec_point,
        s = "",
        toFixedFix = function (n, prec) {
          var k = Math.pow(10, prec);
          return "" + Math.round(n * k) / k;
        };
      // Fix for IE parseFloat(0.55).toFixed(0) = 0;
      s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
      if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
      }
      if ((s[1] || "").length < prec) {
        s[1] = s[1] || "";
        s[1] += new Array(prec - s[1].length + 1).join("0");
      }
      return s.join(dec);
    },
    formatNumber(value) {
      const fieldType = this.$attrs.type || this.type;

      if (fieldType === "number") {
        if (isNaN(value) === false) {
          return this.numberFormat(value, this.decimalPlaces);
        }
      }
      return value;
    },
  },
};
</script>
