import moment from "moment";
import angkaTerbilang from "@develoka/angka-terbilang-js";
import AxiosStorage from "axios-storage";
import config from "@/constants/config";

// eslint-disable-next-line no-unused-vars
// const snackbar = {
//   template: '<div>Micin</div>'
// }

export default {
  data: () => ({}),
  methods: {
    numberFormat(number, decimals, dec_point, thousands_sep) {
      if (typeof number === "undefined" || isNaN(number) == true) {
        return 0;
      }
      // Strip all characters but numerical ones.
      number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
      var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
        dec = typeof dec_point === "undefined" ? "." : dec_point,
        s = "",
        toFixedFix = function (n, prec) {
          var k = Math.pow(10, prec);
          return "" + Math.round(n * k) / k;
        };
      // Fix for IE parseFloat(0.55).toFixed(0) = 0;
      s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
      if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
      }
      if ((s[1] || "").length < prec) {
        s[1] = s[1] || "";
        s[1] += new Array(prec - s[1].length + 1).join("0");
      }
      return s.join(dec);
    },
    setNavDrawerSetOp() {
      var t = this.$store.state.navDrawer;

      if (t == true) {
        this.$store.state.navDrawer = false;
      } else {
        this.$store.state.navDrawer = true;
      }
    },
    ucFirst(string) {
      if (typeof string !== "undefined") return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      return "";
    },
    ucFirstAll(string) {
      if (typeof string == "undefined") return "";

      var str = "";
      string.toLowerCase();

      const myArr = string.split(" ");
      if (typeof myArr !== "undefined") {
        myArr.forEach(function (item) {
          if (typeof item !== "undefined") str = str + " " + item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
        });
      }

      return str;
    },
    numberToShortText(number) {
      if (number < 1000) {
        return this.numberFormat(number);
      }

      if (number >= 1000 && number < 1000000) {
        return this.numberFormat(number / 1000) + "K";
      }

      if (number >= 1000000) {
        return this.numberFormat(number / 1000000) + "M";
      }
    },
    goTo(uri) {
      return this.$router.push(uri);
    },
    redirect(routerName, query = null) {
      if (query !== null) {
        return this.$router.push({ name: routerName, query: query });
      } else {
        return this.$router.push({ name: routerName });
      }
    },
    redirect404() {
      return this.$router.push({ name: "404" });
    },
    goBack() {
      this.$router.back();
    },
    arrayUniqBy(a, f) {
      var rows = [];
      var tmpF = [];
      a.forEach((item) => {
        if (tmpF.includes(item[f]) == false) {
          rows.push(item);
        }

        tmpF.push(item);
      });

      return tmpF;
    },
    showAlert(type = "", message = "") {
      var tpl = config.notificationPlugin;

      if (tpl == "awn") {
        if (type == "success") {
          this.$awn.success(message);
        } else if (type == "info") {
          this.$awn.info(message);
        } else if (type == "warning") {
          this.$awn.warning(message);
        } else if (type == "error") {
          this.$awn.alert(message);
        } else {
          this.$awn.tip(message);
        }
      }

      if (tpl == "notify") {
        if (type == "success") {
          this.$notify({
            group: "default",
            title: "Success",
            text: message,
            type: type,
          });
        } else if (type == "info") {
          this.$notify({
            group: "default",
            title: "Info",
            text: message,
            type: type,
          });
        } else if (type == "warning") {
          this.$notify({
            group: "default",
            title: "Warning",
            text: message,
            type: type,
          });
        } else if (type == "error") {
          this.$notify({
            group: "default",
            title: "Error",
            text: message,
            type: type,
          });
        } else {
          this.$notify({
            group: "default",
            title: "Notification",
            text: message,
          });
        }
      }
    },
    showSnackbar(message) {
      this.$store.state.snackBar.show = true;
      this.$store.state.snackBar.message = message;
    },
    showConfirm(title, message, onOK = null, onCancel = null) {
      if (onOK == null) {
        onOK = () => {
          null;
        };
      }

      if (onCancel == null) {
        onCancel = () => {
          null;
        };
      }

      if (typeof onOK != "function") {
        onOK = false;
      }
      if (typeof onCancel != "function") {
        onCancel = false;
      }

      if (typeof title == "string") {
        if (title == "") title = "";
      } else if (typeof title == "boolean") {
        if (title == false) title = "Confirm";
      } else {
        title = "Confirm";
      }

      this.$awn.confirm(message, onOK, onCancel, {
        labels: {
          confirm: title,
        },
      });
    },
    showLoadingOverlay(show = true) {
      this.$store.state.loadingOverlay = show;
    },
    appDateFormat(date) {
      var _date = moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
      if (_date === "Invalid date") return "";
      return _date;
    },
    appDatetimeFormat(date) {
      var _date = moment(date, "YYYY-MM-DD h:mm:ss").format("dddd, MMMM Do YYYY, h:mm:ss a");
      if (_date === "Invalid date") return "";
      return _date;
    },
    timeAgo(date) {
      const now = new Date();
      const difference = now - new Date(date);

      const seconds = Math.floor(difference / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const months = Math.floor(days / 30);
      const years = Math.floor(months / 12);

      if (years > 0) {
        return years === 1 ? "1 year ago" : years + " years ago";
      } else if (months > 0) {
        return months === 1 ? "1 month ago" : months + " months ago";
      } else if (days > 0) {
        return days === 1 ? "1 day ago" : days + " days ago";
      } else if (hours > 0) {
        return hours === 1 ? "1 hour ago" : hours + " hours ago";
      } else if (minutes > 0) {
        return minutes === 1 ? "1 minute ago" : minutes + " minutes ago";
      } else {
        return seconds === 1 ? "1 second ago" : seconds + " seconds ago";
      }
    },
    terbilang(angka, rd = 2) {
      angka = Number(angka).toFixed(rd);
      return angkaTerbilang(angka);
    },
    setLocalData(key, data, expired) {
      var tmpLocalData = this.$store.state.localData;

      try {
        var objTmpLocalData = JSON.parse(tmpLocalData);

        if (Array.isArray(objTmpLocalData)) {
          var d = {
            key: key,
            data: data,
            expired: expired,
          };
          objTmpLocalData.push(d);

          var jsonTmpLocalData = JSON.stringify(objTmpLocalData);

          this.$store.state.localData = jsonTmpLocalData;

          return true;
        } else {
          var d2 = {
            key: key,
            data: data,
            expired: expired,
          };

          this.$store.state.localData = JSON.stringify(d2);

          return true;
        }
      } catch (e) {
        e.toString();
        return false;
      }
    },
    getLocalData(key) {
      var tmpLocalData = this.$store.state.localData;
      console.log(tmpLocalData);
      try {
        var objTmpLocalData = JSON.parse(tmpLocalData);

        if (Array.isArray(objTmpLocalData)) {
          var selectedItem;
          var items = objTmpLocalData;
          for (var i = 0; i < items.length; i++) {
            var item = items[i];
            if (item.key == key) {
              selectedItem = item.data;
            }
          }

          return selectedItem;
        }
        return null;
      } catch (e) {
        e.toString();
        return null;
      }
    },
    goToExternalURL(url) {
      window.open(url, "_blank") || window.location.replace(url);
    },
    AxiosStorageRemove(method, key) {
      let oCache = AxiosStorage.getCache("sessionStorage");
      var storageKey = method + "." + config.apiUrl + key;
      oCache.remove(storageKey);
    },
    AxiosStorageRemoveAll() {
      let oCache = AxiosStorage.getCache("sessionStorage");
      oCache.removeAll();
    },
    resourceUrl(url) {
      if (url == "") return "";
      // return url + "?token=" + this.$store.state.apiToken;
      return url;
    },
    axiosErrorHandler(error) {
      try {
        if (typeof error.response !== "undefined") {
          var resData = error.response.data;

          if (typeof resData.status != "undefined" && typeof resData.message != "undefined") {
            this.showAlert(resData.status, resData.message);
          }

          if (typeof resData.error_code != "undefined") {
            if (resData.error_code == "app_bpw_maintenance_mode") {
              this.redirect("MaintenanceMode");
            }
          }
        } else {
          this.showAlert("error", config.axiosErrorResponseMessage);
        }
      } catch (err) {
        this.showAlert("error", err.message);
      }
    },
    inArray(val, arr = []) {
      if (arr.length == 0) return false;
      var res = false;
      arr.forEach((a) => {
        if (a === val) {
          res = true;
        }
      });
      return res;
    },

    isLoggedIn() {
      if (this.$store.state.isLoggedIn && this.$store.state.apiToken.toString() !== "") {
        return true;
      }
      return false;
    },

    async getModule() {
      if (!this.isLoggedIn()) return;

      var params = [];

      this.AxiosStorageRemove("GET", "auth/user-module");

      await this.$axios
        .get("auth/user-module", {
          params: params,
        })
        .then((res) => {
          var resData = res.data;
          this.$store.state.userModule = JSON.stringify(resData.data.user_modules);
        })
        .catch((error) => {
          if (error.response) {
            console.log("Error: ", error.response.headers);
          } else {
            console.log("Error: ", error.message);
          }
        });
    },

    async getSetting(name) {
      if (!this.isLoggedIn()) return;

      const uri = "setting/get-setting";

      var params = {
        name: name,
      };

      this.AxiosStorageRemove("GET", uri);

      return await this.$axios.get(uri, {
        params: params,
      });
    },

    optionYesNo(selected = null) {
      if (selected == null) return "";
      if (selected == true) return "Yes";
      if (selected == false) return "No";
      if (selected == 1) return "Yes";
      if (selected == 0) return "No";

      return "";
    },
    ifNull(needle, replaceValue) {
      if (needle == null) return replaceValue;
      return needle;
    },

    isUndefined(needle) {
      return typeof needle == "undefined" ? true : false;
    },

    isNull(needle) {
      return needle == null ? true : false;
    },

    isEmpty(needle) {
      if (this.isNull(needle)) return true;

      return needle.length == 0 ? true : false;
    },

    isBlank(needle) {
      if (this.isNull(needle)) return true;
      if (this.isEmpty(needle)) return true;

      return needle == "" ? true : false;
    },

    setMeta(name, value) {
      if (name == "title") {
        document.title = value;
      }
    },
    appendArray(form_data, values, name) {
      if (!values && name) {
        form_data.append(name, "");
      } else {
        if (typeof values == "object") {
          for (let key in values) {
            if (typeof values[key] == "object") {
              if (typeof name == "undefined") {
                this.appendArray(form_data, values[key], key);
              } else {
                this.appendArray(form_data, values[key], name + "[" + key + "]");
              }
            } else {
              form_data.append(name + "[" + key + "]", values[key]);
            }
          }
        } else {
          form_data.append(name, values);
        }
      }

      return form_data;
    },

    dataTableFormData(datatable_options = {}, search = "", filter = {}, customParams = {}) {
      const { sortBy, sortDesc, page, itemsPerPage } = datatable_options;
      var formData = new FormData();
      this.appendArray(formData, search, "search");
      this.appendArray(formData, sortBy, "sort_by");
      this.appendArray(formData, sortDesc, "sort_desc");
      this.appendArray(formData, page, "page");
      this.appendArray(formData, itemsPerPage, "limit");
      this.appendArray(formData, filter, "filter");

      let customParamsKeys = Object.keys(customParams);
      customParamsKeys.forEach((key) => {
        this.appendArray(formData, customParams[key], key);
      });

      return formData;
    },

    objectToFormData(obj, form = new FormData(), namespace = "") {
      for (let key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
          const formKey = namespace ? `${namespace}[${key}]` : key;

          // Jika nilai adalah objek, panggil rekursif
          if (typeof obj[key] === "object" && !(obj[key] instanceof File) && obj[key] !== null) {
            this.objectToFormData(obj[key], form, formKey);
          } else {
            // Tambahkan field ke FormData
            // console.log(obj[key]);

            if (obj[key] !== null && (typeof obj[key] !== "undefined")) {
              form.append(formKey, obj[key]);
            }
          }
        }
      }

      return form;
    },

    documentStatusColor(status) {
      if (status == "") return "";
      if (status == "open") return "success";
      if (status == "waiting-approval") return "accent";
      if (status == "cancel") return "error";
      if (status == "approved") return "primary";
      if (status == "closed") return "warning";

      return "";
    },

    loadImageError(event) {
      console.log("akjsdhfbgkajsdhfkagh");

      event.target.src = "";
    },

    getFileNameFromUrl(url, withExtension = true) {
      // Reverse the URL string
      const urlReverse = url.split("").reverse().join("");

      // Find the last path by splitting the reversed URL by '/'
      const urlExploded = urlReverse.split("/");

      // If the first part is empty, return an empty string
      if (!urlExploded[0]) return "";

      if (!withExtension) {
        // Check if there's a dot in the filename
        if (urlExploded[0].includes(".")) {
          // Remove the extension and reverse the string back
          const withoutExtension = urlExploded[0].slice(urlExploded[0].indexOf(".") + 1);
          return withoutExtension.split("").reverse().join("");
        } else {
          return "";
        }
      } else {
        // Return the reversed filename with the extension
        return urlExploded[0].split("").reverse().join("");
      }
    },

    ifEmpy(value, replaceValue) {
      if (value == "" || value == null) return replaceValue;
      return value;
    },

    downloadFromBlob(blob, fileName) {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
  },

  computed: {
    isMobileBrowser() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      } else {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return true;
          case "sm":
            return false;
          case "md":
            return false;
          case "lg":
            return false;
          case "xl":
            return false;
          default:
            return false;
        }
      }
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      } else {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return true;
          case "sm":
            return false;
          case "md":
            return false;
          case "lg":
            return false;
          case "xl":
            return false;
          default:
            return false;
        }
      }
    },
  },
};
