const options = {
  mjo: {
    status: ["Open", "Normal", "Critical", "High"],
    substatus: ["received", "on-progress", "waiting-sparepart", "pending", "done"],
    priority: ["Low", "Normal", "Critical", "High"],
    job_type: ["Perbaikan Terencana", "Perawatan Terjadwal", "Perbaikan Tidak Terencana", "Project / Improvement", "Perbaikan Alat Bantu"],
    job_level: ["Sangat Mudah", "Mudah", "Sedang", "Sulit", "Sangat Sulit"],
  },
  units: [
    // Satuan Umum Lainnya
    { code: "UNIT", name: "Unit", description: "Satuan umum untuk barang" },
    { code: "LOT", name: "Lot", description: "Satuan untuk sekumpulan barang atau aset" },
    { code: "PCS", name: "Pieces", description: "Satuan untuk benda individu" },
    { code: "DOZEN", name: "Lusin", description: "12 buah" },
    { code: "GROSS", name: "Gross", description: "144 buah (12 lusin)" },
    { code: "PACK", name: "Pack", description: "Kemasan berisi sejumlah barang" },
    { code: "DUS", name: "Dus", description: "Kotak kemasan besar" },
    { code: "IKAT", name: "Ikat", description: "Sekelompok barang yang diikat bersama" },
    { code: "RIM", name: "Rim", description: "500 lembar kertas" },
    { code: "BALE", name: "Bale", description: "Satuan bundelan besar seperti kain atau kertas" },

    // Satuan Panjang
    { code: "m", name: "Meter", description: "Satuan dasar panjang dalam SI" },
    { code: "km", name: "Kilometer", description: "1.000 meter" },
    { code: "cm", name: "Centimeter", description: "1/100 meter" },
    { code: "mm", name: "Milimeter", description: "1/1.000 meter" },
    { code: "inch", name: "Inci", description: "2,54 cm" },

    // Satuan Massa
    { code: "g", name: "Gram", description: "Satuan dasar massa dalam CGS" },
    { code: "kg", name: "Kilogram", description: "1.000 gram" },
    { code: "mg", name: "Miligram", description: "1/1.000 gram" },
    { code: "lb", name: "Pound", description: "Sekitar 0,4536 kg" },
    { code: "oz", name: "Ons", description: "Sekitar 28,35 gram" },

    // Satuan Waktu
    { code: "s", name: "Detik", description: "Satuan dasar waktu dalam SI" },
    { code: "min", name: "Menit", description: "60 detik" },
    { code: "h", name: "Jam", description: "60 menit" },
    { code: "d", name: "Hari", description: "24 jam" },
    { code: "y", name: "Tahun", description: "365 atau 366 hari" },

    // Satuan Suhu
    { code: "C", name: "Celsius", description: "Satuan suhu berdasarkan titik beku dan didih air" },
    { code: "F", name: "Fahrenheit", description: "Skala suhu yang digunakan di AS" },
    { code: "K", name: "Kelvin", description: "Satuan suhu absolut dalam SI" },

    // Satuan Luas
    { code: "m2", name: "Meter Persegi", description: "Satuan luas dalam SI" },
    { code: "ha", name: "Hektar", description: "10.000 meter persegi" },
    { code: "km2", name: "Kilometer Persegi", description: "1.000.000 meter persegi" },

    // Satuan Volume
    { code: "L", name: "Liter", description: "Satuan volume umum" },
    { code: "m3", name: "Meter Kubik", description: "1.000 liter" },
    { code: "ml", name: "Mililiter", description: "1/1.000 liter" },

    // Satuan Kecepatan
    { code: "m/s", name: "Meter per Detik", description: "Satuan kecepatan dalam SI" },
    { code: "km/h", name: "Kilometer per Jam", description: "Satuan kecepatan umum" },
    { code: "mph", name: "Mil per Jam", description: "Kecepatan dalam sistem imperial" },

    // Satuan Energi & Daya
    { code: "J", name: "Joule", description: "Satuan energi dalam SI" },
    { code: "kJ", name: "Kilojoule", description: "1.000 Joule" },
    { code: "W", name: "Watt", description: "Satuan daya dalam SI" },
    { code: "kW", name: "Kilowatt", description: "1.000 Watt" },

    // Satuan Listrik
    { code: "A", name: "Ampere", description: "Satuan arus listrik dalam SI" },
    { code: "V", name: "Volt", description: "Satuan tegangan listrik" },
    { code: "Ω", name: "Ohm", description: "Satuan hambatan listrik" },

    // Satuan Data & Komputer
    { code: "bit", name: "Bit", description: "Satuan terkecil data digital" },
    { code: "B", name: "Byte", description: "8 bit" },
    { code: "KB", name: "Kilobyte", description: "1.024 byte" },
    { code: "MB", name: "Megabyte", description: "1.024 KB" },
    { code: "GB", name: "Gigabyte", description: "1.024 MB" },
    { code: "TB", name: "Terabyte", description: "1.024 GB" },
  ],
  finance: {
    resume_contract: {
      customer_status: ["", "NEW", "EXISTING"],
    },
    addendum: {
      customer_status: ["", "NEW", "EXISTING"],
    },
  },
};

export default options;
